import React from 'react';

export default function PageNotFound(args) {

  // It's recommended to add your SEO solution in here for bots
  // eg. https://github.com/ahimsayogajp/ahimsayoga-gatsby/blob/master/src/pages/index.js#L22
  return (
    <div>
    PAGE NOT FOUND
    </div>
  );

}
